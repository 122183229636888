/* global window, document */
import { CookieUtils, } from '@haaretz/htz-user-utils';
import config from 'config';
import withTimeout from './withTimeout';
import extractParameter from './extractParamFromUrl';

const statPageTypes = {
  article: 'Article',
  homepage: 'Home',
  section: 'Section',
  personal: 'Personal',
};

const userEntitlement = {
  HTZ: [ 'Haaretz Daily', 'TM Daily + Weekend', ],
  TM: [ 'Haaretz Daily', 'TM Daily + Weekend', 'TM Daily', ],
  HDC: [ 'HDC Daily', ],
};

const getStatPageType = pageType => {
  if (pageType && /article/i.test(pageType)) {
    return statPageTypes.article;
  }
  return statPageTypes[pageType] || pageType;
};

const getPayingUserType = (userProducts, host) => {
  let userType = 'Paying';
  const site = host.includes('themarker.com')
    ? 'TM'
    : host.includes('haaretz.com')
      ? 'HDC'
      : 'HTZ';

  const checkEntitlement = userProducts.reduce(
    (accumulator, vendor) => accumulator
      || (vendor.status === 'SUBSCRIBED'
        && vendor.brand
        && userEntitlement[site]
        && userEntitlement[site].includes(vendor.brand)),
    false
  );

  const isProductMatchDomain = userProducts.reduce(
    (accumulator, vendor) => accumulator
      || (vendor.status === 'SUBSCRIBED'
        && (vendor.brand === site
          || (vendor.brand === 'BOTH' && (site === 'HTZ' || site === 'TM')))),
    false
  );
  if (checkEntitlement) {
    userType = 'Entitlement';
  }
  if (!checkEntitlement && isProductMatchDomain) {
    userType = userProducts.reduce(
      (accumulator, vendor) => accumulator
        || vendor.connectionType === 776
        || vendor.connectionType === 763,
      false
    )
      ? 'App Store'
      : userProducts.isTrial
        ? 'Trial'
        : userProducts.payProp === 1
          ? 'Free'
          : 'Paying';
  }
  return userType;
};

const getRegisteredUserType = (userProducts, host) => {
  const site = host.includes('themarker.com')
    ? 'TM'
    : host.includes('haaretz.com')
      ? 'HDC'
      : 'HTZ';
  const isProductMatchDomain = userProducts.reduce(
    (accumulator, vendor) => accumulator
      || (vendor.status === 'STOPPED'
        && (vendor.brand === site
          || (vendor.brand === 'BOTH' && (site === 'HTZ' || site === 'TM')))),
    false
  );
  const userType = isProductMatchDomain ? 'Stopped' : 'Registered';
  return userType;
};

// TODO: amend jsdoc definition for doStatAction
/**
 * This function is responsible for sending an action code with data
 * to the DS server
 * @export
 * @param {object} action an object containing the action data
 * @param {object} user a plain user object, as defined in @htz-user-utils
 * @param {string} pageType a string
 * @param {string?} articleId an optinal string of the article ID to be sent in article pages
 * @param {boolean} [sendBeacon=true] flag indicating wheather the request should be sent via
 * the sendBeacon API
 * @returns a promise for the request made to the DS server, which resolves to a Response,
 * or rejected if a timeout (default: 5000ms) has been reached, or, in case the sendBeacon option
 * is set, a boolean representing whether the browser has queued the request or not,
 */

export function doStatAction({
  action,
  user,
  userProducts,
  pageType,
  articleId,
  authorsContentId = null,
  isCloseArticle = null,
  isPremiumContent = null,
  platform = 'unset',
  sendBeacon = true,
  paywallType = null,
  miscAdditionalInfo = {},
  articleType = null,
}) {
  const dsActionBase = config.get('service.ds');
  const { additionalInfo, } = action;
  const additionalInfoToStringify = Object.assign({}, additionalInfo, miscAdditionalInfo);
  const serializedAdditionalInfo = Object.keys(additionalInfoToStringify).length ? JSON.stringify(additionalInfoToStringify) : null;
  const href = window && window.location && window.location.href;


  const recommendationType = action.origin
    ? action.origin === 'editors'
      ? 'Editors Pick'
      : action.origin === 'similarArticle'
        ? 'Similar Article'
        : action.origin === 'userHistory'
          ? 'User History'
          : action.origin === 'TmBooster'
            ? 'TM Booster'
            : action.origin === 'colFiltering'
              ? 'Collaborative Filtering'
              : action.origin === 'joni'
                ? 'Joni'
                : action.origin
    : null;

  const userType = !user || user?.type === 'anonymous'
    ? 'Anonymous'
    : user.type === 'paying'
      ? userProducts && getPayingUserType(userProducts, window.location.hostname)
      : user.type === 'registered'
        ? userProducts && getRegisteredUserType(userProducts, window.location.hostname)
        : user.type;

  // haaretz for me page is always a premium content.
  const isHaaretzForMePage = href && href.includes('haaretz-for-me');
  const shouldSendPremiumFields = !!articleId || isHaaretzForMePage;
  // haaretz for me page should be blocked(closed) for Anonymous users.
  const isClosedFrontHaaretzForMe = userType === 'Anonymous' && isHaaretzForMePage;
  const statData = {
    article_id: articleId,
    actionType: action.actionCode,
    page_type: getStatPageType(action?.pageType || pageType),
    url: document.URL || '',
    domain: window.location.hostname || '',
    Rusr: (typeof window !== 'undefined' && CookieUtils.getCookie('user_details')?.userType === 'registered') || null,
    Pusr: (typeof window !== 'undefined' && CookieUtils.getCookie('user_details')?.userType === 'paying') || null,
    aun: CookieUtils.getCookie('aun') || '',
    // userId: user.id || AbuseService.getSsoOnAbusePage() || '',
    anonymousId: window.anonymousId ? window.anonymousId : user.anonymousId || '',
    user_type: userType,
    react: true,
    useragent: window.encodeURIComponent(window.navigator.userAgent),

    platform: platform === 'app' ? 'App' : platform === 'mobile' ? 'Mobile' : 'Desktop',
    ab_test_group: action.abGroup,
    ab_test_group_label: action.abTestGroupLabel,
    ab_test_name: action.abTestName,
    feature: action.feature,
    feature_type: action.featureType,
    article_type: articleType || action.articleType,
    personal_feature: action.personalFeature,
    // newsletter actions
    newsletter_segment_id:
      action.segmentId
      || (action.additionalInfo && action.additionalInfo.segmentId),
    newsletter_segment_name: action.segmentName,
    newsletter_email: action.newsletterEmail,
    // purchase page actions
    prod_no: action.productId,
    promotions_no: action.promotionNumber,
    sale_code: action.saleCode,
    // marketing Tools
    campaign_name: action.campaignName,
    campaign_details: action.campaignDetails,
    // personal_list only for article pages.
    // Remove this condition when homePage lists will be included.
    // ...(articleId && isPersonalActionCheck
    //   ? { personal_list: action.isPersonal, }
    //   : {}),
    ...(action.additionalInfo
      ? {
        stage: action.additionalInfo.stage,
        prod_no:
            action.additionalInfo.chosenProduct
            || action.additionalInfo.productId || action.productId,
        // plan: action.additionalInfo.plan,
        // prod_no: action.additionalInfo.productId,
      }
      : {}),

    next_article_id: action.nextArticleId || action.ArticleId,
    next_article_teaser_id: action.nextArticleTeaserId,

    next_article_list_id: action.ListId,
    next_article_noinlist: action.NoInList,
    next_article_viewname: action.ViewName,
    next_article_list_name: action.ListName,
    advertising_id: window.deviceId ? window.deviceId : null,
    // writer_id: action.writerId,
    writer_name: action.writerName,
    darkmode: (document?.location?.search || '').includes('darkMode=true') || null,
    android_id: window.androidId ? window.androidId : null,
    paywall_status: paywallType,
    blocked: typeof isCloseArticle === 'boolean' ? shouldSendPremiumFields && isCloseArticle : null,
    // EventTracker client query,
    // openmode_back:
    //  shouldSendPremiumFields && (isPremiumContent !== null || isHaaretzForMePage)
    //    ? (isPremiumContent || isHaaretzForMePage ? 0 : 1) // haaretz for me page is always a premium content.
    //    : null, // isOnArticlePage() ? getDataFromElement('statutilispremiumcontent') === 'false' ? 1 : 0 : null,
    openmode_front:
     shouldSendPremiumFields && (isCloseArticle !== null || isHaaretzForMePage)
       ? (isCloseArticle || isClosedFrontHaaretzForMe ? 0 : 1)
       : null, // isOnArticlePage() ? (typeof openMode !== 'undefined' ? openMode : '') : null,
    writer_id: authorsContentId || action.writerId,

    // Include UTM params in action
    utm_medium:
      extractParameter('utm_medium', href)
      || extractParameter('htm_medium', href),
    utm_source:
      extractParameter('utm_source', href)
      || extractParameter('htm_source', href),
    utm_content:
      extractParameter('utm_content', href)
      || extractParameter('htm_content', href),
    utm_campaign:
      extractParameter('utm_campaign', href)
      || extractParameter('htm_campaign', href),
    utm_type:
      extractParameter('utm_type', href) || extractParameter('htm_type', href),
    recommendation_type: recommendationType,
    // General additional Info.
    additionalInfo: serializedAdditionalInfo,
  };

  return sendBeacon && typeof window.navigator.sendBeacon === 'function'
    ? window.navigator.sendBeacon(
      `${dsActionBase}/actionPlain`,
      JSON.stringify(statData)
    )
    : withTimeout(
      window.fetch(`${dsActionBase}/action`, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(statData),
        cache: 'no-cache',
      })
    );
}
export function doStatImpression(
  impression,
  user,
  userProducts,
  pageType,
  articleId,
  authorsContentId = null,
  isCloseArticle = null,
  isPremiumContent = null,
  articleType = null,
  platform = 'unset',
  paywallType = null,
  miscAdditionalInfo = {},
) {
  const dsImpressionBase = config.get('service.ds');
  const { additionalInfo, } = impression;
  const recommendationType = impression.origin
    ? impression.origin === 'editors'
      ? 'Editors Pick'
      : impression.origin === 'similarArticle'
        ? 'Similar Article'
        : impression.origin === 'userHistory'
          ? 'User History'
          : impression.origin === 'TmBooster'
            ? 'TM Booster'
            : impression.origin === 'colFiltering'
              ? 'Collaborative Filtering'
              : impression.origin === 'joni'
                ? 'Joni'
                : impression.origin
    : null;

  const additionalInfoToStringify = Object.assign({}, additionalInfo, miscAdditionalInfo);
  const serializedAdditionalInfo = Object.keys(additionalInfoToStringify).length ? JSON.stringify(additionalInfoToStringify) : null;

  const href = window && window.location && window.location.href;

  const maxScroll = typeof impression.maxScroll === 'number'
    ? Math.round(impression.maxScroll)
    : null;

  const userType = !user || user?.type === 'anonymous'
    ? 'Anonymous'
    : user.type === 'paying'
      ? userProducts && getPayingUserType(userProducts, window.location.hostname)
      : user.type === 'registered'
        ? userProducts && getRegisteredUserType(userProducts, window.location.hostname)
        : user.type;

  // haaretz for me page is always a premium content.
  const isHaaretzForMePage = href && href.includes('haaretz-for-me');
  const shouldSendPremiumFields = !!articleId || isHaaretzForMePage;
  // haaretz for me page should be blocked(closed) for Anonymous users.
  const isClosedFrontHaaretzForMe = userType === 'Anonymous' && isHaaretzForMePage;

  const statData = {
    article_id: articleId,
    scroll_height: maxScroll, // Paywall Y position (int).
    page_type: getStatPageType(impression?.pageType || pageType),
    article_type: articleType,
    url: document.URL || '',
    domain: window.location.hostname || '',
    Rusr: (typeof window !== 'undefined' && CookieUtils.getCookie('user_details')?.userType === 'registered') || null,
    Pusr: (typeof window !== 'undefined' && CookieUtils.getCookie('user_details')?.userType === 'paying') || null,
    aun: CookieUtils.getCookie('aun') || '',
    // userId: user.id || AbuseService.getSsoOnAbusePage() || '',
    anonymousId: window.anonymousId ? window.anonymousId : user.anonymousId || '',
    user_type: userType,
    react: true,
    useragent: window.encodeURIComponent(window.navigator.userAgent),

    platform: platform === 'app' ? 'App' : platform === 'mobile' ? 'Mobile' : 'Desktop',
    // actionType: impression.actionCode,
    ab_test_group: impression.abGroup,
    ab_test_name: impression.abTestName,
    ab_test_group_label: impression.abTestGroupLabel,
    feature: impression.feature,
    feature_type: impression.featureType,
    personal_feature: impression.personalFeature,
    // newsletter actions
    newsletter_segment_id: impression.segmentId,
    newsletter_segment_name: impression.segmentName,
    newsletter_email: impression.newsletterEmail,
    // purchase page actions
    prod_no: impression.productId,
    promotions_no: impression.promotionNumber,
    sale_code: impression.saleCode,
    // marketing Tools
    campaign_name: impression.campaignName,
    campaign_details: impression.campaignDetails,
    // personal_list only for article pages.
    // Remove this condition when homePage lists will be included.
    // ...(articleId ? { personal_list: impression.isPersonal, } : {}),
    // ...(impression.additionalInfo
    //   ? {
    //     stage: impression.additionalInfo.stage,
    //     prod_no: impression.additionalInfo.chosenProduct || impression.additionalInfo.productId,
    //     // plan: action.additionalInfo.plan,
    //     // prod_no: action.additionalInfo.productId,
    //   }
    //   : {}),
    next_article_id: impression.nextArticleId || impression.ArticleId,
    next_article_teaser_id: impression.nextArticleTeaserId,

    next_article_list_id: impression.ListId,
    next_article_noinlist: impression.NoInList,
    next_article_viewname: impression.ViewName,
    next_article_list_name: impression.ListName,
    advertising_id: window.deviceId ? window.deviceId : null,

    writer_name: impression.writerName,
    // writer_id: impression.writerId,
    paywall_status: paywallType,
    darkmode: (document?.location?.search || '').includes('darkMode=true') || null,
    android_id: window.androidId ? window.androidId : null,
    blocked: typeof isCloseArticle === 'boolean' ? shouldSendPremiumFields && isCloseArticle : null,
    // openmode_back:
    // shouldSendPremiumFields && (isPremiumContent !== null || isHaaretzForMePage)
    //   ? (isPremiumContent || isHaaretzForMePage ? 0 : 1) // haaretz for me page is always a premium content.
    //   : null, // isOnArticlePage() ? getDataFromElement('statutilispremiumcontent') === 'false' ? 1 : 0 : null,
    openmode_front:
    shouldSendPremiumFields && (isCloseArticle !== null || isHaaretzForMePage)
      ? (isCloseArticle || isClosedFrontHaaretzForMe ? 0 : 1)
      : null, // isOnArticlePage() ? (typeof openMode !== 'undefined' ? openMode : '') : null,
    writer_id: authorsContentId,

    // Include UTM params in action
    utm_medium:
      extractParameter('utm_medium', href)
      || extractParameter('htm_medium', href),
    utm_source:
      extractParameter('utm_source', href)
      || extractParameter('htm_source', href),
    utm_content:
      extractParameter('utm_content', href)
      || extractParameter('htm_content', href),
    utm_campaign:
      extractParameter('utm_campaign', href)
      || extractParameter('htm_campaign', href),
    utm_type:
      extractParameter('utm_type', href) || extractParameter('htm_type', href),
    recommendation_type: recommendationType,
    // General additional Info.
    additionalInfo: serializedAdditionalInfo,
  };

  return withTimeout(
    window.fetch(`${dsImpressionBase}/impression`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(statData),
      cache: 'no-cache',
    })
  );
}

/**
 * This function is responsible for sending a request to the DS server on pageload
 * @export
 * @param {object} user a plain user object, as defined in @htz-user-utils
 * @param {object[]} lineage optional list which contains article content id and sections pathSegments
 * @param {string} writerId  optional article's writer id.
 * @param {object} user a plain user object, as defined in @htz-user-utils
 * @returns a promise for the request made to the DS server, which resolves to a Response,
 * or rejected if a timeout (default: 5000ms) has been reached
 */
export function doStat({
  user,
  userProducts,
  pageType,
  articleType = null,
  lineage = [],
  writerName = null,
  writerId = null,
  isArticlePage = false,
  isPremiumContent = null,
  isBlocked = null,
  additionalData = {},
  platform = 'unset',
  paywallType = null,
}) {
  let articleId = null;

  if (lineage?.length > 1) {
    articleId = (pageType !== 'section' && lineage[0].contentId) || null;
  }

  const dsRequestService = `${config.get('service.ds')}/request`;

  const href = window && window.location && window.location.href;

  let isSearchDialog = false;
  const searchDialog = extractParameter('searchDialog', href);
  if (searchDialog && searchDialog === 'open') {
    isSearchDialog = true;
  }


  const userType = !user || user?.type === 'anonymous'
    ? 'Anonymous'
    : user.type === 'paying'
      ? userProducts && getPayingUserType(userProducts, window.location.hostname)
      : user.type === 'registered'
        ? userProducts && getRegisteredUserType(userProducts, window.location.hostname)
        : user.type;

  // haaretz for me page is always a premium content.
  const isHaaretzForMePage = href && href.includes('haaretz-for-me');
  const shouldSendPremiumFields = !!articleId || isHaaretzForMePage;
  // haaretz for me page should be blocked(closed) for Anonymous users.
  const isClosedFrontHaaretzForMe = userType === 'Anonymous' && isHaaretzForMePage;

  const additionalInfoToStringify = Object.assign({}, additionalData);
  const serializedAdditionalInfo = Object.keys(additionalInfoToStringify).length ? JSON.stringify(additionalInfoToStringify) : null;

  const statData = {
    ...additionalData,
    user_type: userType,
    // sso_id: user.id || AbuseService.getSsoOnAbusePage() || '',
    anonymous_id: window.anonymousId ? window.anonymousId : user.anonymousId || '',
    article_type: articleType,
    page_type: isSearchDialog ? 'Search' : getStatPageType(pageType),
    ip: null,
    Rusr: (typeof window !== 'undefined' && CookieUtils.getCookie('user_details')?.userType === 'registered') || null,
    Pusr: (typeof window !== 'undefined' && CookieUtils.getCookie('user_details')?.userType === 'paying') || null,
    device_type: null,
    device_name: null,
    os: null,
    useragent: window.encodeURIComponent(window.navigator.userAgent),
    ad_block_state: window.localStorage.hasAdBlock || 'false',
    width: `${window.screen.availWidth}`,
    height: `${window.screen.availHeight}`,
    request_time: new Date(),
    url: window.document.URL,
    site: window.document.location.origin,
    domain: window.location.hostname,
    article_id: articleId,
    writer_name: writerName,
    writer_id: writerId, // getDataFromElement('statutilWriter', { aggregate: true, }) || '',
    paywall_status: paywallType,
    blocked: typeof isBlocked === 'boolean' ? shouldSendPremiumFields && isBlocked : null,

    // openmode_back:
    // shouldSendPremiumFields && (isPremiumContent !== null || isHaaretzForMePage)
    //   ? (isPremiumContent || isHaaretzForMePage ? 0 : 1) // haaretz for me page is always a premium content.
    //   : null, // isOnArticlePage() ? getDataFromElement('statutilispremiumcontent') === 'false' ? 1 : 0 : null,
    openmode_front:
    shouldSendPremiumFields && (isBlocked !== null || isHaaretzForMePage)
      ? (isBlocked || isClosedFrontHaaretzForMe ? 0 : 1)
      : null, // isOnArticlePage() ? (typeof openMode !== 'undefined' ? openMode : '') : null, // Defined in global scope.. :-(
    utm_medium:
      extractParameter('utm_medium', href)
      || extractParameter('htm_medium', href),
    utm_source:
      extractParameter('utm_source', href)
      || extractParameter('htm_source', href),
    utm_content:
      extractParameter('utm_content', href)
      || extractParameter('htm_content', href),
    utm_campaign:
      extractParameter('utm_campaign', href)
      || extractParameter('htm_campaign', href),
    utm_type:
      extractParameter('utm_type', href) || extractParameter('htm_type', href),
    referrer_type: window.document.referrer,
    platform: platform === 'app' ? 'App' : platform === 'mobile' ? 'Mobile' : 'Desktop',
    additionalInfo: serializedAdditionalInfo,
    advertising_id: window.deviceId ? window.deviceId : null,
    darkmode: (document?.location?.search || '').includes('darkMode=true') || null,
    android_id: window.androidId ? window.androidId : null,
  };

  return withTimeout(
    window.fetch(`${dsRequestService}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(statData),
      cache: 'no-cache',
    })
  );
}
